.etf-insights-footer{
  background-color: #002b5a;

  .footer-wrapper {
    background-color:#002b5a;
    width: 100%;
    bottom: 0;
    margin: auto;
    max-width: 1366px;
  }
  
  .btn{
    border-radius: 50%;
    background: #3078b5;
    width: 44px;
    height: 44px;
    border: none;
    float: right;
    margin-right: 40px;
    margin-top: -20px;
    color: #fff;
  }
  
  .logo {
    color:#fff;
    float: left;
    width: 200px;
    max-width: 100%;
    height: 50px;
  }

  p{
    margin: 0px;
  }
  
  .divider {
    border-top: 1px solid;
    color: #002b5a;
    margin:0;
  }
  
  .vr{
    display: inline;
    height: 16px;
    margin: 0px;
  }

  
  a{
    color: #fff;
    text-decoration: none;
    font-size: 15px;
    font-family: GraphikRegular;
  }

  .a1{
  padding-right: 12px;
  margin: 0px;
  }

  .a2{
    padding-left: 12px;
    margin: 0px;
  }
  
   @media only screen and (max-width: 700px) {

  .year span{
    display: block;
  }

  .a1{
    padding-left: 40px;
  }
    
  }
  
  
  }