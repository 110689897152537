.highcharts-tooltip {
  table {
    font-family: 'GraphikRegular';
    font-size: 14px;
    color: #57626a;
  }

  table th {
    border-bottom: 1px solid #4375BB;
    color: #007BB8
  }

  table td .leftPadding {
    padding-left: 24px;
  }

  table td .rightPadding {
    padding-right: 24px;
  }

  table td div {
    padding-top: 12px;
    padding-right: 12px;
  }

  table td div:last-child {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  table td div span {
    padding-right: 12px;
  }

  table td div span:last-child {
    padding-right: 0px;
  }

  table th div {
    padding: 12px 24px;
    display: block;
  }

  table td span .dot {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }
}